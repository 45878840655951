<template>
		<div>
			<v-row>
				<v-col cols="12" md="8" lg="9" class="align-self-end">
					<v-card>
						<v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
							<span class="text-no-wrap">Bienvenue </span>
							<span class="text-no-wrap font-weight-bold mx-1" v-text="profile.lastname.toUpperCase() + ' ' + profile.firstname + ' !'"></span>
						</v-card-title>
					</v-card>
				</v-col>
				<v-col cols="12" md="4" lg="3" class="align-self-end">
					<v-card>
						<v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
							<span class="text-no-wrap" v-text=" 'Role : ' +  roleTable[profile.role]"></span>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="align-self-end">
					<v-card>
						<v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
                            <v-img :src="isDark ? appDarkLogo : appLogo" contain class="app-logo me-3" max-height="50vh"></v-img>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
		</div>
</template>

<script>
import themeConfig from "@themeConfig"

export default {
	props: ["profile"],
    setup() {
        return{
            isDark: themeConfig.app.isDark,
            appLogo: themeConfig.app.logo,
            appDarkLogo: themeConfig.app.darkLogo,
        }
    },
	data() {
		return {
			roleTable:{
				'Corrector': 'Correcteur',
				'Animator': 'Animateur',
				'Admin': 'Admin',
				'SuperAdmin': 'Super Admin',
			},
		}
	},
}
</script>
