<template>
    <div class="d-flex align-center px-2">
        <div v-if="['mail', 'phone'].includes(type)" class="d-flex align-center my-2">
            <UserAvatar v-if="data" :profile="data.sender"></UserAvatar>
            <div class="ml-3">
                <b v-text="data ? data.sender.lastname + ' ' + data.sender.firstname : ''"></b>
                <div v-text="'Sujet : ' + (data ? data.subject : '')"></div>
            </div>
        </div>

        <div v-else-if="['agenda', 'todo', 'file'].includes(type)" class="d-flex align-center my-2">
            <v-avatar size="40px" class="primary--text" color="darkBg">
                <v-icon v-if="type === 'agenda'">mdi-calendar-month</v-icon>
                <v-icon v-else-if="type === 'todo'">mdi-format-list-checks</v-icon>
                <v-icon v-else-if="type === 'file'">mdi-file-outline</v-icon>
            </v-avatar>
            <div class="ml-3">
                <b v-text="data ? data.name : ''"></b>
                <div v-if="type === 'agenda'" v-text="data ? 'Le ' + new Date(data.start).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) + ' à ' + new Date(data.start).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) + '.' : ''"></div>
                <div v-else-if="type === 'todo'" class="max-1-line" v-text="data ? data.desc : ''"></div>
                <div v-else-if="type === 'file'" class="max-1-line" v-text="data ? (data.type === 'folder' ? 'Nouveau dossier' : 'Nouveau fichier') : ''"></div>
            </div>
        </div>

        <v-spacer></v-spacer>
        <v-btn icon @click.prevent.stop="deleteNotif">
            <v-icon small>mdi-check</v-icon>
        </v-btn>
    </div>
</template>

<script>
// classes
import Profile from "@/classes/Profile"
import Contact from "@/classes/Contact"

import Mail from "@/classes/Mail"
import Phone from "@/classes/Phone"
import Event from "@/classes/Event"
import Task from "@/classes/Task"
import File from "@/classes/File"

// components
import UserAvatar from "@/components/UserAvatar"

export default {
    props: ['type', 'notif', 'contactsTable'],
    components: {
        UserAvatar
    },
    data: () => ({
        data: null
    }),
    async created() {
        this.data = await this.getNotifData(this.notif.content)
    },
    methods: {
        async getNotifData(id) {
            let item
            switch (this.type) {
                case 'mail':
                    item = await Mail.getById(id)
                    item.sender = await this.getContact(item.sender)
                    break
                case 'phone':
                    item = await Phone.getById(id)
                    item.sender = await this.getContact(item.sender)
                    break
                case 'agenda':
                    item = await Event.getById(id)
                    break
                case 'todo':
                    item = await Task.getById(id)
                    break
                case 'file':
                    item = await File.getById(id)
                    break
            }
            return item
        },
        async getContact(id) {
            if (this.contactsTable[id])
                return this.contactsTable[id]
            else {
                let tmp_data
                tmp_data = await Contact.getById(id)
                if (!tmp_data)
                    tmp_data = await Profile.getById(id)

                return tmp_data
            }
        },
        deleteNotif(){
            this.notif.delete()
        }
    }
}
</script>