<template>
	<div>
		<v-row>
			<v-col cols="12" :md="UserNote != null ? 8 : 12" :lg="UserNote != null ? 9 : 12">
				<v-card>
                    <v-card-title style="word-break:unset;" class="text-2xl" v-text="'Bienvenue ' + profile.lastname.toUpperCase() + ' ' + profile.firstname + ' !'"/>
				</v-card>
			</v-col>
			<v-col cols="12" md="4" lg="3" v-if="UserNote != null">
				<v-card>
                    <v-card-title class="text-2xl" v-text="'Score : ' + UserNote + '/' + MaxNote"/>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="6">
				<v-card>
                    <v-card-title>Avancement des tâches :</v-card-title>
                    <v-card-text class="d-flex align-center justify-center mt-4">
                        <div style="height:35em;max-height:500px;max-width: 100%;aspect-ratio:1/1;">
                            <vue-apex-charts type="donut" height="100%" :options="CompletionChart.chartOptions" :series="tasksCompletionRate"/>
                        </div>
                    </v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" md="6">
				<v-card height="100%" style="display:grid;grid-template-rows: auto 1fr;">
                    <v-card-title>Notifications :</v-card-title>
                    <v-card-text width="100%" class="pa-5 mt-0">

                        <v-expansion-panels accordion v-model="expansionPanelsModel" v-if="checkIfNotif">
                            <v-expansion-panel>
                                <v-btn text @click="deleteAllNotif" width="100%" class="ma-0">
                                    <v-divider class="mx-3"/>
                                    <p class="mb-0">Supprimer toutes les notifications.</p>
                                    <v-divider class="mx-3"/>
                                </v-btn>
                            </v-expansion-panel>

                            <v-expansion-panel v-for="notifInfo of getNotifMessages" v-if="notifInfo.nb > 0">
                                <v-expansion-panel-header hide-actions>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-2">{{ notifInfo.icon }}</v-icon>
                                        <div class="d-flex flex-column">
                                            <div>Vous avez <b>{{ notifInfo.nb }}</b> {{ notifInfo.nb > 1 ? notifInfo.plural : notifInfo.singular }}.</div>
                                        </div>
                                        <v-spacer/>
                                        <v-btn icon @click.prevent.stop="deleteAllNotifOfType(notifInfo.type)">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-simple-table>
                                        <tbody>
                                            <tr v-for="notif of notifications[notifInfo.type]" @click="$router.push(notifInfo.router)" class="cursor-default">
                                                <notification-card :type="notifInfo.type" :notif="notif" :contactsTable="contactsTable"/>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <div v-else style="width:100%; height:50%;" class="d-flex flex-column align-center">
                            <v-spacer/>
                            <v-icon x-large>mdi-bell-outline</v-icon>
                            <v-spacer/>
                            <p>Vous recevez vos notifications en direct.</p>
                            <p>Le son peut être désactivé dans <a @click="$router.push('profil')">votre profil.</a></p>
                        </div>

                    </v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
// components
import notificationCard from "@/components/Home/NotificationCard.vue"
import VueApexCharts from "vue-apexcharts"

// config
import themeConfig from "@themeConfig"

// classes
import Note from "@/classes/Note"
import Mail from "@/classes/Mail"
import Phone from "@/classes/Phone"
import MailResponse from "@/classes/MailResponse"
import PhoneResponse from "@/classes/PhoneResponse"

export default {
    props: ['profile', 'notifications'],
    components: {
        notificationCard,
        VueApexCharts
    },
    data: () => ({
        // score
        notes:[],

        // donut
        CompletionChart: {
            series: [0, 0, 100],
            chartOptions: {
                labels: ['Finis', 'En attente'],
                colors: [themeConfig.themes.light.primary, themeConfig.themes.light.light],
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: true,
                    formatter(val) {
                        return `${parseInt(val)}%`
                    },
                },
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '2rem',
                                },
                                value: {
                                    fontSize: '1rem',
                                    formatter(val) {
                                        return `${parseInt(val)}%`
                                    },
                                },
                                total: {
                                    show: false,
                                },
                            },
                        },
                    },
                },
            },
        },
        contactsTable: {},
        mails:[],
        phones:[],

        // notifications
        expansionPanelsModel: 1,
        notifInfos: {
            mail: {
                router: 'email',
                singular: 'nouveau mail',
                plural: 'nouveaux mails',
                icon: 'mdi-email-outline'
            },
            phone: {
                router: 'phone',
                singular: 'nouveau message audio',
                plural: 'nouveaux messages audio',
                icon: 'mdi-phone-outline'
            },
            agenda: {
                router: 'agenda',
                singular: 'nouveau rendez-vous',
                plural: 'nouveaux rendez-vous',
                icon: 'mdi-calendar-month'
            },
            todo: {
                router: 'todo',
                singular: 'nouvelle tâche',
                plural: 'nouvelles tâches',
                icon: 'mdi-format-list-checks'
            },
            file: {
                router: 'files',
                singular: 'nouveau fichier',
                plural: 'nouveaux fichiers',
                icon: 'mdi-folder-outline'
            }
        },

        unsub: [],
    }),
    created() {
        // score
        this.unsub.push(
            Note.listenByRoom(this.profile.currentRoom, data => {
                this.notes = data.filter(note => note.visible)
                this.notes.sort((a, b) => {
                    return a.date - b.date
                })
            })
        )

        // donut
        this.unsub.push(
            Phone.listenByRoom(this.profile.currentRoom, async data => {
                const tmp_phones = []

                for (const phone of data) {
                    if (phone.responseType != 'none')
                        phone.response = await PhoneResponse.getByPhoneAndSender(phone.id, this.profile.id)

                    tmp_phones.push(phone)
                }

                tmp_phones.sort((a, b) => {
                    return a.date - b.date
                })
                this.phones = tmp_phones
            })
        )
        this.unsub.push(
            Mail.listenByUserReceived(this.profile, async data => {
                const tmp_mails = []

                for (const mail of data) {
                    if (mail.responseType != 'none')
                        mail.response = await MailResponse.getByMailAndSender(mail.id, this.profile.id)

                    tmp_mails.push(mail)
                }

                tmp_mails.sort((a, b) => {
                    return a.date - b.date
                })
                this.mails = tmp_mails
            })
        )
    },
    computed: {
        // score
        UserNote() {
            let oneIsVisible = false
            let sum = 0
            for (const note of this.notes)
                if (note.visible && note.notes[this.profile.id]) {
                    oneIsVisible = true
                    sum += parseFloat(note.notes[this.profile.id].note)
                }

            if (oneIsVisible)
                return Math.round(sum*100)/100
            return null
        },
        MaxNote(){
            let sum = 0
            for (const note of this.notes)
                if (note.visible && note.notes[this.profile.id])
                    sum += parseFloat(note.noteMax)

            return Math.round(sum*100)/100
        },


        // donut
        tasksCompletionRate(){
            let totalTasks = 0
            let completedTasks = 0
            let invisibleTasks = 0

            for (const mail of this.mails) {
                if (mail.responseType != 'none') {
                    totalTasks++
                    if (new Date(mail.date).getTime() > new Date().getTime())
                        invisibleTasks++
                    else if (mail.response)
                        completedTasks++
                }
            }
            for (const message of this.phones) {
                if (message.responseType != 'none') {
                    totalTasks++
                    if (new Date(message.date).getTime() > new Date().getTime())
                        invisibleTasks++
                    else if (message.response)
                        completedTasks++
                }
            }
            
            let tmp_series = []
            if (totalTasks > 0) {

                let completionRate = Math.round(completedTasks / (totalTasks- invisibleTasks) * 10000) / 100
                
                //let completionRate = Math.round(completedTasks / totalTasks * 10000) / 100
                //let invisibleRate = Math.round(invisibleTasks / totalTasks * 10000) / 100
                tmp_series = [completionRate, 100 - completionRate ]
            } else {
                tmp_series = [0, 100]
            }

            return tmp_series
        },


        // notifications
        getNotifMessages() {
            const notifsTable = []

            // notifsTable don't have empty notif type unlike this.notifInfos
            let index = 0
            for (const key in this.notifInfos) {
                if (this.notifications[key]) {
                    notifsTable.push(this.notifInfos[key])
                    notifsTable[index].type = key
                    notifsTable[index].nb = this.notifications[key].length
                    index++
                }
            }

            notifsTable.sort((a,b) => {
                return b.type - a.type
            })

            return notifsTable
        },
        checkIfNotif() {
            for (const key in this.notifications)
                for (const notif of this.notifications[key])
                    return true
            return false
        }
    },
    methods: {
        // notifications
        deleteAllNotifOfType(type) {
            for (const notif of this.notifications[type])
                notif.delete()
        },
        deleteAllNotif() {
            for (const key in this.notifications)
                for (const notif of this.notifications[key])
                    notif.delete()
        }
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    },
}
</script>

<style scoped>
.v-btn:not(:has(.v-icon)) {
    overflow:hidden;
    padding: 0!important;
    border-radius:0;
    justify-content: unset!important;
    word-spacing: 0.2em!important;
    letter-spacing: 0.075em!important;
    text-transform: none;
}
.v-btn:not(:has(.v-icon)) p {
    white-space: normal!important;
}
</style>