<template>
    <v-card>
        <v-card-text class="d-flex flex-column align-center">
            <h1>Votre profil n'est pour le moment pas activé.</h1>
            <h3 class="mt-5">Rendez vous dans votre profil pour rejoindre une Room ou contacter un administrateur pour activer votre profil correcteur ou animateur.</h3>
            <v-btn color="primary" class="mt-5" outlined @click="$router.push('profil')">Profil</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ["profile"]
}
</script>