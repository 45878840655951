<template>
    <span>
        <UserHome v-if="['User'].includes(profile.role)"
            :profile="profile" :notifications="notifications"
        />
        <AdminHome  v-else-if="['Corrector', 'Animator', 'Admin', 'SuperAdmin'].includes(profile.role)"
            :profile="profile"
        />
        <NoneHome v-else
            :profile="profile"
        />
    </span>
</template>

<script>
import NoneHome from '@/components/Home/NoneHome.vue'
import UserHome from '@/components/Home/UserHome.vue'
import AdminHome from '@/components/Home/AdminHome.vue'

export default {
    props: ['profile', 'notifications'],
    components: {
        NoneHome,
        UserHome,
        AdminHome,
    }
}
</script>
